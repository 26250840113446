import React, {
  Fragment,
} from 'react';
import styled from 'styled-components';
import {
  boldFontWeight,
  demiFontWeight,
} from '../cssVariables';
import {
  escapeKeyCode,
  xIconHTMLEntity,
} from '../Utils';
import {
  DisplayValueStatus,
  IDetailOverlayRow as IRow,
} from '../viz/Utils';
import {
  UIControlsContainer,
  vizGridZIndices,
} from '../viz/VizGrid';

// Convert from an array of `IRow` to React elements, accounting for all the
// possible types of values:
export const getRows = (rows: IRow[], Container: React.ComponentType) => {
  const rowElems: Array<React.ReactElement<any> | null> = rows.map(({label, value}, index) => {

    let result: JSX.Element | null;
    if (typeof value === 'string' || typeof value === 'number') {
      result = (
        <Fragment key={`row-${index}`}>
          <Container>{label}</Container>
          <Container>{value}</Container>
        </Fragment>
      );
    } else {
      if (value.status === DisplayValueStatus.Show ||
          value.status === DisplayValueStatus.ShowNotApplicable ||
          value.status === DisplayValueStatus.ShowNotAvailable) {

        let displayedValue: string | number;
        if (value.status === DisplayValueStatus.Show) {
          displayedValue = value.value;
        } else if (value.status === DisplayValueStatus.ShowNotApplicable) {
          displayedValue = __lexiconText('detailOverlay.notApplicable');
        } else {
          displayedValue = __lexiconText('detailOverlay.notAvailable');
        }

        result = (
          <Fragment key={`row-${index}`}>
            <Container>{label}</Container>
            <Container>{displayedValue}</Container>
          </Fragment>
        );
      } else {
        result = null;
      }
    }
    return result;
  });
  return rowElems;
};

export {IRow};

// `position: relative` is always needed so that the overlay appears in front of
// the country/product/partner dropdowns:
const Root = styled(UIControlsContainer)`
  z-index: ${vizGridZIndices.detailOverlay};
  background-color: white;
  border-width: 10px;
  border-style: solid;
  font-weight: ${demiFontWeight};
  padding-left: 0;
  margin-left: 2.5rem;
`;

const ContentInnerContainer = styled.div`
  margin: 0 auto;
  padding: 0.5rem 1.5rem;
`;

const closeXSize = '1.2rem';
const CloseX = styled.div`
  width: ${closeXSize};
  height: ${closeXSize};
  font-size: ${closeXSize};
  top: 0.25rem;
  margin-left: auto;
  margin-right: 0.25rem;
  cursor: pointer;
  color: #666;
  position: sticky;
`;

const CloseButton = styled.button`
  text-transform: uppercase;
  font-size: 1rem;
  padding: 10px 20px 10px 20px;
  border: 1px solid #333;
  font-weight: 400;
  display: block;
  margin: 4rem auto 0;
  background-color: transparent;

  &:hover {
    color: #fff;
    background-color: #333;
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-weight: ${demiFontWeight};
  font-size: 0.95rem;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 2fr) 1fr;
  grid-auto-rows: minmax(2rem, max-content);
  font-size: 0.8rem;
  margin: 1rem 0;
`;

const CustomContent = styled.div`
  font-size: 0.8rem;
`;

const cellBorderColor = '#ddd';

const TableItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${cellBorderColor};
  padding: 0.5rem 0;

  &:nth-child(2n + 1) {
    justify-content: flex-start;
  }

  &:nth-child(2n) {
    justify-content: flex-end;
    text-align: right;
    font-weight: ${boldFontWeight};
  }
`;

export interface IProps {
  rows: IRow[];
  color: string;
  title: string;
  hideOverlay: () => void;
  rememberEl?: (el: HTMLElement | null) => void;
}

export default class extends React.PureComponent<IProps, {}> {
  private rememberEl = (el: HTMLElement | null) => {
    const {rememberEl} = this.props;
    if (rememberEl !== undefined) {
      rememberEl(el);
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', this.listenToKeyEvents);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.listenToKeyEvents);
  }

  private listenToKeyEvents = ({keyCode}: KeyboardEvent) => {
    if (keyCode === escapeKeyCode) {
      this.props.hideOverlay();
    }
  }

  private onCloseButtonClick = () => this.props.hideOverlay();

  render() {
    const {color, rows, title} = this.props;
    const style = {
      borderColor: color,
    };
    const rowElems = getRows(rows, TableItem);
    return (
      <Root style={style} ref={this.rememberEl}>
        <CloseX
          onClick={this.onCloseButtonClick}
          dangerouslySetInnerHTML={{__html: xIconHTMLEntity}}/>
        <ContentInnerContainer>
          <Title>{title}</Title>
          <Table>{rowElems}</Table>
          <CustomContent>
            {this.props.children}
          </CustomContent>
          <CloseButton onClick={this.onCloseButtonClick}>Close</CloseButton>
        </ContentInnerContainer>
      </Root>
    );
  }
}
