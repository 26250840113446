import React, {useEffect, useState} from 'react';

import {
    NewChartHeader,
    NewChartSimpleTitle,
    NewChartHeaderTopRow,
    NewChartHeaderTopRowCell1,
    NewChartHeaderTopRowCell1Label,
    NewChartHeaderTopRowCell1Arrow,
    NewChartHeaderTopRowCell2,
    NewChartHeaderTopRowCell3,
    NewChartHeaderTopRowCell4,
    NewChartHeaderBottomRow,
    NewChartHeaderBottomRowCell1,
    NewChartHeaderBottomRowCell2,
    NewChartHeaderBottomRowCell3,
    NewChartHeaderBottomRowCell4,
    ChartTitle,
    NewClippedChartTitle
} from './VizGrid';

import Tooltip from './Tooltip';


import ChartShownTitle from './ChartShownTitle';
import { VizType} from './Utils';

import {
    generateStringProductId,
    generateStringLocationOrGroupId
  } from '../graphQL/Utils';

import {
    ProductClass,
    TradeDirection,
    QueryLevel,
    DataFacet
} from '../graphQL/types/shared';
import {
TreeMapType,
} from '../graphQL/types/shared';
import {
extractFromStringProductId,
} from '../graphQL/Utils';
import {
failIfValidOrNonExhaustive,
} from '../Utils';

import {gql, useQuery} from '../graphQL/useQuery';
import { DocumentNode } from 'graphql';



interface IEntityDatum {
    id: string;
    shortName: string;
}

interface IFromURLParams {
    type: TreeMapType;
    location: string | null;
    product: string | null;
    partner: string | null;
    year: number;
    tradeDirection: TradeDirection;
    }

interface IInput<T extends IEntityDatum, U extends IEntityDatum> {
    inputFromURLParams: IFromURLParams;
    locationsData: T[];
    hsProductsData: U[];
    sitcProductsData: U[];
}


const queryLocationAndProduct = gql`
  query titleInformationLocationAndProduct($locationId: ID!, $productId: ID!) {
    location(id: $locationId) {
      id
      shortName
    }
    product(id: $productId) {
      id
      shortName
    }
  }
`;

const queryLocationAndLocation = gql`
  query titleInformationLocationAndProduct($locationId: ID!, $partnerId: ID!) {
    location: location(id: $locationId) {
      id
      shortName
    }
    partner: location(id: $partnerId) {
        id
        shortName
      }
  }
`;

const queryLocationAndGroup = gql`
  query titleInformationLocationAndProduct($locationId: ID!, $partnerId: ID!) {
    location: location(id: $locationId) {
      id
      shortName
    }
    partner: group(id: $partnerId) {
        id
        shortName: groupName
      }
  }
`;

const queryLocation = gql`
  query titleInformationLocation($locationId: ID!) {
    location(id: $locationId) {
      id
      shortName
    }
  }
`;

const queryProduct = gql`
  query titleInformationProduct($productId: ID!) {
    product(id: $productId) {
      id
      shortName
    }
  }
`;

const queryGroup = gql`
  query titleInformationGroup($locationId: ID!) {
    location: group(id: $locationId) {
      id
      shortName: groupName
    }
  }
`;

const queryGroupAndProduct = gql`
  query titleInformationGroupAndProduct($locationId: ID!, $productId: ID!) {
    location: group(id: $locationId) {
      id
      shortName: groupName
    }
    product(id: $productId) {
      id
      shortName
    }
  }
`;

const queryGroupAndLocation = gql`
  query titleInformationGroupAndProduct($locationId: ID!, $partnerId: ID!) {
    location: group(id: $locationId) {
      id
      shortName: groupName
    }
    partner: location(id: $partnerId) {
        id
        shortName
      }
  }
`;

const queryGroupAndGroup = gql`
  query titleInformationGroupAndProduct($locationId: ID!, $partnerId: ID!) {
    location: group(id: $locationId) {
      id
      shortName: groupName
    }
    partner: group(id: $partnerId) {
        id
        shortName: groupName
      }
  }
`;

interface SuccessResponse {
  location?: {
    id: Location['id'],
    shortName: Location['shortName'],
  } | null;
  product?: {
    id: Product['id'],
    shortName: Product['shortName'],
  } | null;
}

interface Variables {
  locationId?: string;
  productId?: string;
}

interface Props {
  country: number | undefined;
  queryLevel: QueryLevel;
  product: number | undefined;
  tradeDirection: TradeDirection;
  year: number;
  productClass: ProductClass;
  setTitle?: (val: string) => void;
}


export const NewGraphTitle = (props) => {
    const {
        country, queryLevel, product, partner, tradeDirection, year, startYear, endYear, productClass, setTitle,
        vizType, facet, graphTotal
      } = props;

      const [graphTitle, setGraphTitle] = useState<string>('');

      const updateGraphTitle = (val: string) => {
        if (val !== graphTitle) {
          setGraphTitle(val);
        }
      };
    
      useEffect(() => {
        if (setTitle) {
          setTitle(graphTitle);
        }
      }, [graphTitle]);


    const locationId = (country != 0 && !country) ? '' : generateStringLocationOrGroupId(country, queryLevel);
    const productId = (product != 0 && !product) ? '' : generateStringProductId({productClass, id: product});
    const partnerId = (partner != 0 && !partner) ? '' : partner;
    const partnerQueryLevel = partnerId.includes("group") ? QueryLevel.Group : QueryLevel.Location;
    
    
    let variables: Variables = {locationId, productId};
    let query: DocumentNode = queryLocationAndProduct;
    if(queryLevel === QueryLevel.Location) {
        if (locationId && !productId && !partnerId) {
            query = queryLocation;
            variables = {locationId};
        } else if (productId && !locationId && !partnerId) {
            query = queryProduct;
            variables = {productId};
        } else if(locationId && productId && !partnerId) {
            query = queryLocationAndProduct;
            variables = {productId, locationId};
        } else if(locationId && partnerId) {
            if(partnerQueryLevel === QueryLevel.Location) {
                query = queryLocationAndLocation;
            } else if(partnerQueryLevel === QueryLevel.Group) {
                query = queryLocationAndGroup;
            }
            variables = {locationId, partnerId};
        }
    
    } else if(queryLevel === QueryLevel.Group) {
        if (locationId && !productId && !partnerId) {
            query = queryGroup;
            variables = {locationId};
        } else if (productId && !locationId && !partnerId) {
            query = queryProduct;
            variables = {productId};
        } else if(productId && locationId && !partnerId) {
            query = queryGroupAndProduct;
            variables = {productId, locationId};
        } else if(locationId && partnerId) {
            if(partnerQueryLevel === QueryLevel.Location) {
                query = queryGroupAndLocation;
            } else if(partnerQueryLevel === QueryLevel.Group) {
                query = queryGroupAndGroup;
            }
            variables = {locationId, partnerId};
        }
    }
    const response = useQuery<SuccessResponse, Variables>(query, {variables});
    const {loading, error, data} = response;

    let content;
    if (loading === true) {
      content = null;
    } else if (error !== undefined) {
      console.error(error);
      content = null;
    } else if (data !== undefined) {
        const { location, product: productData, partner} = data;


        let arrowRight = require('../img/svgs-loaded-with-file-loader/arrow-right.svg');  
        let arrowLeft = require('../img/svgs-loaded-with-file-loader/arrow-left.svg');  

        let exporterCountryDisplayValue = "---";
        let importerCountryDisplayValue = "---";
        let productDisplayValue = "---";
            let yearDisplayValue = "---";
        const yearLabel = vizType === VizType.Stack ? "Years" : "Year"; 
        let productClassLabel;
        if(productClass === ProductClass.HS) {
            productClassLabel = "HS";
        } else if(productClass === ProductClass.SITC) {
            productClassLabel = "SITC";
        }


        if(facet === DataFacet.CPY_C) {
            if(tradeDirection === TradeDirection.export) {
                exporterCountryDisplayValue = location.shortName;
                importerCountryDisplayValue = "All countries";
            } else if(tradeDirection === TradeDirection.import) {
                importerCountryDisplayValue = location.shortName;
                exporterCountryDisplayValue = "All countries";
            }
            productDisplayValue = `All products`;
        } else if(facet === DataFacet.CPY_P) {
            if(tradeDirection === TradeDirection.export) {
                exporterCountryDisplayValue = "All countries";
                importerCountryDisplayValue = "---";
            } else if(tradeDirection === TradeDirection.import) {
                importerCountryDisplayValue = "All countries";
                exporterCountryDisplayValue = "---";
    
            }
            productDisplayValue = `${productData.shortName} (${productClassLabel})`;
        } else if(facet === DataFacet.CCY_C) {
            if(tradeDirection === TradeDirection.export) {
                exporterCountryDisplayValue = location.shortName;
                importerCountryDisplayValue = "All countries";
            } else if(tradeDirection === TradeDirection.import) {
                importerCountryDisplayValue = location.shortName;
                exporterCountryDisplayValue = "All countries";
            }
            productDisplayValue = `All products`;
        } else if(facet === DataFacet.CCPY_CC) {
            if(tradeDirection === TradeDirection.export) {
                exporterCountryDisplayValue = location.shortName;
                importerCountryDisplayValue = partner.shortName;
            } else if(tradeDirection === TradeDirection.import) {
                importerCountryDisplayValue = location.shortName;
                exporterCountryDisplayValue = partner.shortName;
            }

            productDisplayValue = `All products`;
        } else if(facet === DataFacet.CCPY_CP) {   
            if(tradeDirection === TradeDirection.export) {
                exporterCountryDisplayValue = location.shortName;
                importerCountryDisplayValue = "All countries";

            } else if(tradeDirection === TradeDirection.import) {
                importerCountryDisplayValue = location.shortName;
                exporterCountryDisplayValue = "All countries";
            }

            productDisplayValue = `${productData.shortName} (${productClassLabel})`;
        }

        if(!locationId && !productId) {
            yearDisplayValue = "---";
        } else if(startYear && endYear) {
            yearDisplayValue = `${startYear} – ${endYear}`;
        } else if(year) {
            yearDisplayValue = year;
        }

        let chartShownTitleElem: JSX.Element | null = null;
        if(graphTotal) {
            chartShownTitleElem = <ChartShownTitle text={graphTotal} />
        }

        let apostropheSuffix: string;
        if(vizType === VizType.Network) {
            if(locationId) {
                apostropheSuffix = location.shortName[location.shortName.length-1] === "s" ? "’" : "’s";
                let title = `${location.shortName}${apostropheSuffix} Product Space, ${year}`;
                let titleElem = 
                    <ChartTitle>
                        <Tooltip
                        explanation={title}
                        title={title}
                        />
                    </ChartTitle>;
    
                content = <>
                    <NewChartSimpleTitle>{titleElem}</NewChartSimpleTitle>
                    {chartShownTitleElem}
                </>;
    
            } else {
                content = null;
            }

        } else if(vizType === VizType.Feasibility) {
            if(locationId) {
                apostropheSuffix = location.shortName[location.shortName.length-1] === "s" ? "’" : "’s";

                let title = `${location.shortName}${apostropheSuffix} Feasible Opportunities, ${year}`;
                let titleElem = 
                <ChartTitle>
                    <Tooltip
                    explanation={title}
                    title={title}
                    />
                </ChartTitle>;
    
                content = <>
                    <NewChartSimpleTitle>{titleElem}</NewChartSimpleTitle>
                    {chartShownTitleElem}
                </>;
            } else {
                content = null;
            }

        } else if(vizType === VizType.Rings) {
            if(locationId) {
                apostropheSuffix = location.shortName[location.shortName.length-1] === "s" ? "’" : "’s";
                let title = `${location.shortName}${apostropheSuffix} Related Products, ${year}`;
                let titleElem = 
                <ChartTitle>
                    <Tooltip
                    explanation={title}
                    title={title}
                    />
                </ChartTitle>;
                content = <>
                    <NewChartSimpleTitle>{titleElem}</NewChartSimpleTitle>
                    {chartShownTitleElem}
                </>;
            } else {
                content = null;
            }

        } else {
            // let tradeDirectionArrow;
            // if(tradeDirection === TradeDirection.export) {
            //     tradeDirectionArrow = arrowRight;
            // } else if(tradeDirection === TradeDirection.import) {
            //     tradeDirectionArrow = arrowLeft;
            // }
            content = 
            <>
                <NewChartHeaderTopRow>
                    <NewChartHeaderTopRowCell1>
                        <NewChartHeaderTopRowCell1Label>
                            Exporter
                        </NewChartHeaderTopRowCell1Label>
                        {/* <NewChartHeaderTopRowCell1Arrow>
                            <img src={tradeDirectionArrow} />

                        </NewChartHeaderTopRowCell1Arrow> */}
                    </NewChartHeaderTopRowCell1>
                    <NewChartHeaderTopRowCell2>
                        Importer
                    </NewChartHeaderTopRowCell2>
                    <NewChartHeaderTopRowCell3>Product</NewChartHeaderTopRowCell3>
                    <NewChartHeaderTopRowCell4>{yearLabel}</NewChartHeaderTopRowCell4>
                </NewChartHeaderTopRow>
                <NewChartHeaderBottomRow>
                <NewChartHeaderBottomRowCell1>
                    <NewClippedChartTitle>
                    <Tooltip
                        explanation={`Exporter: ${exporterCountryDisplayValue}`}
                        title={exporterCountryDisplayValue}
                    />
                    </NewClippedChartTitle>
                    {/* {countryDisplayValue1} */}
                </NewChartHeaderBottomRowCell1>
                <NewChartHeaderBottomRowCell2>
                    <NewClippedChartTitle>
                    <Tooltip
                        explanation={`Importer: ${importerCountryDisplayValue}`}
                        title={importerCountryDisplayValue}
                    />
                    </NewClippedChartTitle>
                    {/* {countryDisplayValue2} */}
                </NewChartHeaderBottomRowCell2>
                <NewChartHeaderBottomRowCell3>
                    <NewClippedChartTitle>
                    <Tooltip
                        explanation={productDisplayValue === 'All products' ? `Product: All products (${productClassLabel} Classification)` : `Product: ${productDisplayValue}`}
                        title={productDisplayValue}
                    />
                    </NewClippedChartTitle>
                    {/* {productDisplayValue} */}
                </NewChartHeaderBottomRowCell3>
                <NewChartHeaderBottomRowCell4>
                    <NewClippedChartTitle>
                    <Tooltip
                        explanation={`Years: ${yearDisplayValue}`}
                        title={yearDisplayValue}
                    />
                    </NewClippedChartTitle>
                </NewChartHeaderBottomRowCell4>
            </NewChartHeaderBottomRow>
            {chartShownTitleElem}
            </>;
        }


        // The following section sets a sentence-based title
        // for select visualizations, which is useful
        // for setting file names for some visualization types
        // when downloading images of the visualization
        if(setTitle) {

            let titleToSet;
            let phraseBeforeYear: string;

            let tradeWord;
            if(tradeDirection) {
                if(tradeDirection === TradeDirection.export) {
                    tradeWord = 'export';
                } else if(tradeDirection === TradeDirection.import) {
                    tradeWord = 'import';
                }
            } else {
                // Custom switch for product space visualization, which is exported products by default
                tradeWord = 'export';
            }
            const preposition = (tradeDirection === TradeDirection.export) ? 'to' : 'from';
            let countryName, productName, partnerName;

            if(facet === DataFacet.CPY_C) {
                if(locationId) {
                    countryName = location.shortName;
                    phraseBeforeYear = `What did ${countryName} ${tradeWord}`;    
                } else {
                    phraseBeforeYear = '';
                }
                
            } else if(facet === DataFacet.CPY_P) {
                if(productId) {
                    productName = productData.shortName;
                    const tradeWordPastTense = (tradeDirection === TradeDirection.export) ? 'exported' : 'imported';
                    phraseBeforeYear = `Who ${tradeWordPastTense} ${productName}`;    
                } else {
                    phraseBeforeYear = '';
                }

            } else if(facet === DataFacet.CCY_C) {
                if(locationId) {
                    countryName = location.shortName;
                    phraseBeforeYear = `Where did ${countryName} ${tradeWord} ${preposition}`;    
                } else {
                    phraseBeforeYear = '';
                }

            } else if(facet === DataFacet.CCPY_CC) {
                if(locationId && partnerId) {
                    countryName = location.shortName;
                    partnerName = partner.shortName;
                    phraseBeforeYear = `What did ${countryName} ${tradeWord} ${preposition} ${partnerName}`;
    
                } else {
                    phraseBeforeYear = '';
                }

            } else if(facet === DataFacet.CCPY_CP) {   
                if(locationId && productId) {
                    countryName = location.shortName;
                    productName = productData.shortName;
                    phraseBeforeYear = `Where did ${countryName} ${tradeWord} ${productName} ${preposition}`;    
                } else {
                    phraseBeforeYear = '';
                }
            }

        
            if(vizType === 'feasibility') {
                titleToSet = `Which products are feasible for ${countryName} in ${year}`;
                updateGraphTitle(titleToSet);
            } else if(phraseBeforeYear !== '') {
                let yearPhrase = '';
                if(startYear && endYear) {
                    yearPhrase = `between ${startYear} and ${endYear}`;
                } else if(year) {
                    yearPhrase = `in ${year}`;
                }
                titleToSet = `${phraseBeforeYear} ${yearPhrase}?`;
                updateGraphTitle(titleToSet);    
            }

        }


    } else {
        content = null;
    }

    return <NewChartHeader>
        {content}
    </NewChartHeader>
}

const getProductOrLocationInfo = <T extends IEntityDatum, U extends IEntityDatum>(input: IInput<T, U>): string => {
    const {inputFromURLParams: urlParams, locationsData, groupsData, queryLevel, hsProductsData, sitcProductsData} = input;

    const findLocationInfo = (id: string, queryLevel: QueryLevel): T => {
        let foundLocation;
        if(queryLevel === QueryLevel.Location) {
        foundLocation = locationsData.find(elem => elem.id === id);
        } else if(queryLevel === QueryLevel.Group) {
        foundLocation = groupsData.find(elem => elem.id === id);

        }
        if (foundLocation === undefined) {
        throw new Error('Cannot find location data for ID ' + location);
        }
        return foundLocation;
    };


    const findProductInfo = (id: string): U => {
        const {productClass} = extractFromStringProductId(id);
        let productsData: typeof hsProductsData;
        if (productClass === ProductClass.HS) {
        productsData = hsProductsData;
        } else if (productClass === ProductClass.SITC) {
        productsData = sitcProductsData;
        } else {
        failIfValidOrNonExhaustive(productClass , 'Invalid product class ' + productClass);
        // The following lines will never be executed:
        productsData = [];
        }
        const foundProduct = productsData.find(elem => elem.id === id);
        if (foundProduct === undefined) {
        throw new Error('Cannot find product data for ID ' + id);
        }
        return foundProduct;
    };

    const {type, tradeDirection, location, product, partner, year} = urlParams;

    let locationInfo;
    let productInfo;
    let tradeWord: string = (tradeDirection === TradeDirection.export) ? 'export' : 'import';
    // const preposition = (tradeDirection === TradeDirection.export) ? 'to' : 'from';
    if (type === TreeMapType.CPY_C) {
        if (location === null) {
        throw new Error('location must not be null in CPY_C');
        }
        locationInfo = findLocationInfo(location, queryLevel);

    } else if (type === TreeMapType.CPY_P) {
        if (product === null) {
        throw new Error('product must not be null in CPY_P');
        }
        productInfo = findProductInfo(product);
    } else if (type === TreeMapType.CCY_C) {
        if (location === null) {
        throw new Error('location must not be null in CCY_C');
        }
        locationInfo = findLocationInfo(location, queryLevel);

    } else if (type === TreeMapType.CCPY_CP) {
        if (location === null || product === null) {
        throw new Error('Both location and product be not null in CCPY_CP');
        }
        locationInfo = findLocationInfo(location, queryLevel);
        productInfo = findProductInfo(product);
    } else if (type === TreeMapType.CCPY_CC) {
        if (location === null || partner === null) {
        throw new Error('Both location and partner must be not null in CCPY_CC');
        }
        locationInfo = findLocationInfo(location, queryLevel);
        partnerInfo = findLocationInfo(partner, queryLevel);
    } else {
        failIfValidOrNonExhaustive(type, 'Invalid tree map type ' + type);
        // The following lines will never be executed:
        phraseBeforeYear = '';
    }


    return {
        locationInfo,
        productInfo,
        year, 
        tradeWord,
    }
};
  

export default (vizType, facet, input, graphTotal) => {

    const {inputFromURLParams: urlParams, locationsData, groupsData, queryLevel, hsProductsData, sitcProductsData} = input;

    const {type, location, product, partner, year, tradeDirection} = urlParams;

    const yearLabel = vizType === VizType.Stack ? "Years" : "Year"; 
    const graphTitleInfo = getProductOrLocationInfo(input);

    let countryDisplayValue1, countryDisplayValue2, productDisplayValue;
    // export enum DataFacet {
    //     CPY_C = 'CPY_C',
    //     CPY_P = 'CPY_P',
    //     CCPY_CP = 'CCPY_CP',
    //     CCPY_CC = 'CCPY_CC',
    //     CCY_C = 'CCY_C',
    //   }
    if(facet === DataFacet.CPY_C) {
        countryDisplayValue1 = graphTitleInfo.locationInfo.shortName;
        countryDisplayValue2 = "All countries";
        productDisplayValue = "All products";
    } else if(facet === DataFacet.CPY_P) {
        countryDisplayValue1 = "All countries";
        countryDisplayValue2 = "---";
        productDisplayValue = graphTitleInfo.productInfo.shortName;
    } else if(facet === DataFacet.CCY_C) {
        countryDisplayValue1 = graphTitleInfo.locationInfo.shortName;
        countryDisplayValue2 = "All countries";
        productDisplayValue = "All products";
    } else if(facet === DataFacet.CCPY_CC) {
        countryDisplayValue1 = graphTitleInfo.locationInfo.shortName;
        countryDisplayValue2 = partner;
        productDisplayValue = "All products";
    } else if(facet === DataFacet.CCPY_CP) {   
        countryDisplayValue1 = graphTitleInfo.locationInfo.shortName;
        countryDisplayValue2 = "All countries";
        productDisplayValue = graphTitleInfo.productInfo.shortName;
    }
    const newChartTitle = 
    <NewChartHeader>
        <NewChartHeaderTopRow>
            <NewChartHeaderTopRowCell1>
                <NewChartHeaderTopRowCell1Label>
                    {tradeDirection === TradeDirection.export ? "Exporter" : "Importer"}
                </NewChartHeaderTopRowCell1Label>
                <NewChartHeaderTopRowCell1Arrow></NewChartHeaderTopRowCell1Arrow>

            </NewChartHeaderTopRowCell1>
            <NewChartHeaderTopRowCell2>
                {tradeDirection === TradeDirection.export ? "Importer" : "Exporter"}
            </NewChartHeaderTopRowCell2>
            <NewChartHeaderTopRowCell3>Product</NewChartHeaderTopRowCell3>
            <NewChartHeaderTopRowCell4>{yearLabel}</NewChartHeaderTopRowCell4>
        </NewChartHeaderTopRow>
    <NewChartHeaderBottomRow>
        <NewChartHeaderBottomRowCell1>
            {countryDisplayValue1}
        </NewChartHeaderBottomRowCell1>
        <NewChartHeaderBottomRowCell2>
            {countryDisplayValue2}
        </NewChartHeaderBottomRowCell2>
        <NewChartHeaderBottomRowCell3>
            {productDisplayValue}
        </NewChartHeaderBottomRowCell3>
        <NewChartHeaderBottomRowCell4>
            {year}
        </NewChartHeaderBottomRowCell4>
    </NewChartHeaderBottomRow>
    {/* <NewChartTitle>
    <Tooltip
        explanation={graphTitle}
        title={graphTitle}
    />
    {graphTitle}
    </NewChartTitle> */}
    <ChartShownTitle
    text={graphTotal}
    />
    </NewChartHeader>;

    return newChartTitle;
}

