import once from 'lodash-es/once';
import * as Sentry from "@sentry/react";

const {
  performanceMetricsGAEventName,
  pageLoadPerformanceMarkerName,
} = require('../buildConstants');

// Note: window.HERO_ELEMENT_NAME and window.HERO_ELEMENT_NAMES is defined per
// route and is set in the HTML document's head: via a template helper:
const HERO_ELEMENT_NAME = (window as any).HERO_ELEMENT_NAME;
const HERO_ELEMENT_NAMES = (window as any).HERO_ELEMENT_NAMES;
const measureName = 'hero-element-time';
const endMarkerName = 'hero-element-shown';

const actuallySendHeroElementTiming = once((heroElementName: string) => {
  if ('performance' in window && performance.mark !== undefined && performance.measure !== undefined) {
    performance.mark(endMarkerName);
    requestIdleCallback(() => {
      // Measure and send message only when browser is idle:
      try {
        performance.measure(
          measureName, pageLoadPerformanceMarkerName, endMarkerName,
        );
        const measures = performance.getEntriesByName(measureName);
        if (measures.length > 0) {
          const measure = measures[0];
          // Note: can only send integer values:
          const time = Math.round(measure.duration);
          (window as any).gtag('event', 
            'Hero Element',
            {
              'event_category': performanceMetricsGAEventName,
              'event_label': heroElementName,
              'non_interaction': true,
              'value': time
            });

            (window as any).gtag('event', 
              'Hero Element Timing',
              {
              'event_category': 'load',
              'value': time,
              'event_label': heroElementName,
          });

        }
      } catch (e) {
        console.error(e);
        if(SENTRY_ENABLED) {
          Sentry.captureException(e);
        }
        // CATCH SENTRY ALERT HERE
        // const RAVEN_STATUS = window.RAVEN_STATUS;
        // if (RAVEN_STATUS.isEnabled) {
        //   RAVEN_STATUS.Raven.captureMessage(e, {
        //     extra: {measureName, pageLoadPerformanceMarkerName, endMarkerName},
        //     level: 'warning',
        //   });
        // }
      }
    });
  }
});

export const sendHeroElementTiming = (heroElementName: string) => {
  if (heroElementName === HERO_ELEMENT_NAME || HERO_ELEMENT_NAMES.includes(heroElementName)) {
    actuallySendHeroElementTiming(heroElementName);
  }
};
