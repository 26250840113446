import React from 'react';
import { ChartShown } from './VizGrid';

interface Props {
  text: React.ReactElement<any> | null | string;
}

const ChartShownTitle = (props: Props) => {
  const { text } = props;

  return (
    <ChartShown>
      {text}
    </ChartShown>
  );
};

export default ChartShownTitle;
