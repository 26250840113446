import debounce from 'lodash-es/debounce';

export interface IListener {
  before: () => void;
  measure: () => void;
  after: () => void;
}
class ResizeMeasurementListener {
  private listeners: IListener[] = [];
  addListener(listener: IListener) {
    this.listeners.push(listener);
    this.enableOrDisable();
  }
  removeListener(listener: IListener) {
    this.listeners = this.listeners.filter(elem => elem !== listener);
    this.enableOrDisable();
  }
  enableOrDisable() {
    if (this.listeners.length === 0) {
      window.removeEventListener('resize', this.respondToResize);
    } else {
      window.addEventListener('resize', this.respondToResize);
    }
  }

  private actuallyRespondToResize = () => {
    requestAnimationFrame(() => {
      this.listeners.forEach(({before}) => before());
      requestAnimationFrame(() => {
        this.listeners.forEach(({measure}) => measure());
        requestAnimationFrame(() => {
          this.listeners.forEach(({after}) => after());
        });
      });
    });
  }
  respondToResize = debounce(this.actuallyRespondToResize, 350);

}

export default new ResizeMeasurementListener();
