import range from 'lodash-es/range';
import React from 'react';
import {
  ProductClass,
} from '../../graphQL/types/shared';
import {
  getPercentageScaleInfo,
  MajorTickMark,
  MinorTickMark,
  TickLabel,
  TicksContainer,
} from './Utils';

interface IProps {
  productClass: ProductClass;
}
// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--percentage-from-left'?: string;
  }
}
export default class extends React.PureComponent<IProps> {
  render() {
    const {productClass} = this.props;

    const {scale, minYear, maxYear} = getPercentageScaleInfo(productClass);

    const majorTickValues = scale.ticks();
    const majorTickValuesWithCoords = majorTickValues.map(value => ({
      year: value,
      percentageFromLeft: scale(value),
    }));

    const allYears = range(minYear, maxYear + 1);
    const minorTickValues = allYears.filter(elem => !majorTickValues.includes(elem));

    const majorTickMarks = majorTickValuesWithCoords.map(({year, percentageFromLeft}) => {
      const style = {
        '--percentage-from-left': `${percentageFromLeft}%`,
      };
      return (
        <MajorTickMark style={style} key={`${year}-major-tick`}/>
      );
    });

    const tickLabels = majorTickValuesWithCoords.map(({year, percentageFromLeft}) => {
      const style = {
        '--percentage-from-left': `${percentageFromLeft}%`,
      };
      return (
        <TickLabel style={style} key={`${year}-label`}>{year}</TickLabel>
      );
    });

    const minorTickMarks = minorTickValues.map(value => {
      const percentageFromLeft = scale(value);
      const style = {
        '--percentage-from-left': `${percentageFromLeft}%`,
      };
      return (
        <MinorTickMark style={style} key={`${value}-minor-tick`}/>
      );
    });

    return (
      <TicksContainer>
        {[...majorTickMarks, ...minorTickMarks, ...tickLabels ]}
      </TicksContainer>
    );
  }
}
